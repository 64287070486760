.app-FourPoints {
  background: #f3f4f6;
  background: linear-gradient(to top, #f5f5f5, #ffffff);
  overflow: hidden;
  transform: translateZ(0);

  .anvil {
    height: 64px;
    width: 112px;
    background: url('../images/anvil-icon.png');
    background-size: cover;
    margin: 0;
    // margin-left: -10px;
    margin: 0 auto 30px;
    display: block;

    @media (min-width: 768px) {
      margin: 0 auto 35px;
    }
  }

  .heading,
  .subHeading {
    text-align: center;
  }

  .mobileImg {
    display: none;

    @media (max-width: 768px) {
      display: block;
      width: 70%;
      object-fit: contain;
      object-position: center;
      margin: 40px auto 35px auto;
    }
  }

  .featureHeading {
    font-family: 'FF Mark W05';
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 0.75px;
  }

  .featureGrid {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-content: stretch;
    align-items: stretch;
  }

  .featureBlock:nth-child(1) {
    order: 0;
    flex: 0 0 29%;
    align-self: auto;
    z-index: 2;
  }

  .featureBlock:nth-child(2) {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;

    img {
      height: 700px;
      object-fit: contain;
      object-position: center;
      // margin: 0 25px;
      position: relative;
      z-index: 2;

      @media (max-width: 1024px) {
        height: 600px;
      }
    }

    img.blurBg {
      position: absolute;
      filter: blur(100px);
      transform: scale(4.5, 1.2);
      z-index: 1;
      opacity: 0.175;
    }
  }

  .featureBlock:nth-child(3) {
    order: 0;
    flex: 0 0 29%;
    align-self: auto;
    z-index: 2;
  }

  .featureBlock {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 768px) {
      &:nth-child(1) {
        order: 1;
        flex: 0 0 48%;
        margin: 0 10px 0 0;
      }
      &:nth-child(3) {
        order: 2;
        flex: 0 0 48%;
      }

      &:nth-child(2) {
        display: none;
      }

      h3,
      p {
        text-align: left;
      }

      i {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }

    .feature {
      margin: 40px 0;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-content: center;
      align-items: center;
      text-align: center;
      padding: 30px;

      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.015),
        0px 0px 15px rgba(0, 0, 0, 0.01), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
      background: rgba(255, 255, 255, 0.25);

      @media (max-width: 1024px) {
        padding: 5px;
        background: none;
        box-shadow: none;
      }

      @media (max-width: 768px) {
        margin: 15px 0;
        padding: 0;
        background: none;
        box-shadow: none;
        display: block;

        i {
          height: 75px;
        }
      }

      h3 {
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: 800;
      }

      p {
        font-size: 17px;
        line-height: 26px;
        color: rgb(75, 85, 99);
      }

      i {
        background-repeat: no-repeat;
        background-position: center center;
        width: 75px;
        min-height: 75px;
        margin: 0 auto;
        background-size: cover;
        display: block;
        margin-bottom: 20px;
        flex: 0 0 20%;
        align-self: flex-start;
      }

      &.feature1 {
        i {
          background-image: url('../images/features-page-point-1.png');
        }
      }

      &.feature2 i {
        background-image: url('../images/features-page-point-2.png');
      }
      &.feature3 {
        i {
          background-image: url('../images/features-page-point-3.png');
        }
      }

      &.feature4 i {
        background-image: url('../images/features-page-point-4.png');
      }

      div {
        flex: 0 1 100%;
        align-self: flex-start;
      }
    }
  }
}
