.app-CollectionCTA {
  overflow: hidden;
  background-size: cover;
  background-position: center top;
  text-align: center;
  background-image: url('../images/battle-scene.jpg');
  box-shadow: inset 0px 4px 3px rgba(17, 11, 7, 0.18),
    inset 0px -4px 3px rgba(19, 13, 8, 0.18);

  h2 {
    font-family: 'FF Mark W05';
    text-transform: uppercase;
    font-weight: 800;
    color: white;
    letter-spacing: 0.125em;
  }

  p {
    color: #ffe1b9;
    text-align: center;
    font-weight: 400;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.45), 0 0 15px rgba(0, 0, 0, 0.3);
  }

  .collectionCTA {
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.7);
    border: 2px solid rgba(255, 173, 119, 0.23);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5), inset 0 0 5px rgba(0, 0, 0, 0.9),
      0 0 20px rgba(0, 0, 0, 0.2);
    padding: 0px 30px;
    color: #ffffff;
    height: 70px;
    transition: 0.3s;
    line-height: 62px;
    // font-family: 'FF Mark W05';
    // text-transform: uppercase;
    // font-size: 16px;
    // font-weight: 800;
    // letter-spacing: 0.125em;

    .fa {
      font-size: 18px;
      margin-left: 10px;
      color: white;
      transition: 0.3s;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.95);
      border: 2px solid rgba(255, 173, 119, 0.4);
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5), inset 0 0 5px rgba(0, 0, 0, 0.9),
        0 0 20px rgba(0, 0, 0, 0.3), 0px 0px 70px #ffa86525;
    }

    &:active {
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.55),
        inset 0 0 5px rgba(0, 0, 0, 0.9), 0 0 20px rgba(0, 0, 0, 0.5),
        0px 0px 70px #ffa86525, inset 0px 0px 10px #ffa86525;
    }
  }
}
