.app-Footer {
  background-color: #14161d;
  box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.3);

  max-width: 100vw;

  img {
    margin-left: -5px;
    margin-top: -5px;
  }
}
