.app-Header {
  width: 100%;
  height: 80px;
  color: white;
  background-color: #14161d;
  border-bottom: 2px solid #21242f;
  position: fixed;
  top: 0;
  z-index: 11;

  h1 a {
    background: url('../images/logo.png') no-repeat center center;
    background-size: contain;
    display: block;
    width: 90px;
    height: 80px;
    text-indent: -999rem;
    margin: 0;
  }

  .headerCTA {
    background: #fbce00;
    padding: 0.6rem 1.4rem;
    text-shadow: 0px 1px 0.5px rgba(255, 235, 144, 0.35);
    color: black;
    font-weight: bold;
    border-radius: 3px;
    font-family: 'FF Mark W05';
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 800;
    text-shadow: 0px 1px 0px rgba(255, 235, 144, 0.55);
    letter-spacing: 0.125em;
  }

  .headerCTA {
    position: relative;
    overflow: hidden;
    transition: 0.3s;
    display: inline-block;
    box-shadow: inset 0px 0px 0px 3px #fbce00;

    &::before {
      content: '';
      display: block;
      position: absolute;
      background: rgba(255, 242, 188, 0.3);
      width: 60px;
      height: 100%;
      top: 0;
      filter: blur(30px);
      transform: translateX(-100px) skewX(-15deg);
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      background: rgba(255, 217, 67, 0.3);
      width: 30px;
      height: 100%;
      top: 0;
      filter: blur(5px);
      transform: translateX(-100px) skewX(-15deg);
    }

    &::before,
    &::after {
      animation: goldFlash 4.5s linear 2s infinite;
    }

    &:hover {
      // transform: scale(1.015);
      cursor: pointer;
      background: #f5c001;
      box-shadow: inset 0px 0px 0px 3px #ffea8873;

      &::before,
      &::after {
        transform: translateX(400px) skewX(-15deg);
        transition: 1.3s;
        animation: none;
      }
    }

    @keyframes goldFlash {
      0% {
        transform: translateX(-100px) skewX(-15deg);
      }
      25% {
        transform: translateX(400px) skewX(-15deg);
      }
      100% {
        transform: translateX(400px) skewX(-15deg);
      }
    }
  }

  .navLinks li a {
    font-family: 'FF Mark W05';
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    color: #f9f9f9;
    display: block;
    height: 80px;
    line-height: 80px;
    letter-spacing: 0.125em;
    transition: 0.4s;
    border-bottom: 2px solid #21242f;

    &.headerCTA {
      display: none;
    }
  }

  .navLinks li a:hover {
    border-color: rgba(141, 153, 211, 0.25);
    // background: radial-gradient(
    //   ellipse at bottom,
    //   #8c85ff7d -35%,
    //   transparent 70%
    // );
    // background-position: 0px 70px;
    // background-repeat: no-repeat;
    // background-size: 100% 10%;
  }

  .navLinks li a.active,
  .navLinks li a:active,
  .navLinks li a:focus {
    border-color: #645be8;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(
        ellipse at bottom,
        #8c85ff7d -35%,
        transparent 70%
      );
      background-position: 0px 70px;
      background-repeat: no-repeat;
      background-size: 100% 10%;
      opacity: 0;
      transition: 0.4s;
    }

    &:hover {
      &:after {
        opacity: 0.5;
      }
    }
  }

  .navLinks li a:active,
  .navLinks li a:focus {
    &:after {
      opacity: 0;
    }
  }

  @media screen and (max-width: 1024px) {
    .mobileNav {
      width: 100%;
      margin: 80px 0 0 0;
      background: #14161d;

      // todo
      &:not(.-mobileNav-open) {
        display: none;
      }

      li {
        margin: 0 !important;

        a {
          height: 70px;
          line-height: 70px;

          &.active {
            border-color: #21242f;
          }

          &:hover {
            background-color: #21242f2f;
          }

          &.headerCTA {
            display: block;
            font-weight: 800;
            color: black;
            font-size: 14px;
            padding: 0;
            border-radius: 0px;
            border-bottom: none;

            &:hover {
              background-color: #ffdd40;
            }
          }
        }
      }
    }
  }

  .basketQuantity {
    position: absolute;
    right: -7px;
    width: 14px;
    height: 14px;
    line-height: 14px;
    text-align: center;
    top: -5px;
    background: rgba(187, 55, 55, 0.9);
    border-radius: 999px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 800;
    color: white;
  }
}
