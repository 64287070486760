.app-Materials {
  text-align: center;

  .heading {
    display: inline-block;
    text-align: center;
    width: auto;
    margin: 0 auto;
    margin-bottom: 100px;
    padding-bottom: 45px;
    border-bottom: 3px solid rgba(0, 0, 0, 0.075);
  }

  .materialBox {
    img {
      margin-bottom: 28px;
      object-fit: contain;
    }

    h3 {
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: 800;
      text-transform: uppercase;
      font-family: 'FF Mark W05';
      letter-spacing: 0.75px;
    }

    p {
      font-size: 18px;
      line-height: 26px;
      color: rgb(75, 85, 99);
    }
  }
}
