.app-GoldCallout {
  .renderBg {
    background: url('../images/gold-callout.jpg');
    background-size: cover;
    background-position: center 190px;
    padding-bottom: 350px;

    @media (max-width: 768px) {
      padding-bottom: 150px;
      background-position: center 250px;
    }
  }
}
