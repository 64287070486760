.app-DealsCTA {
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    mix-blend-mode: multiply;
    opacity: 0.7;
  }

  .goldButton {
    color: #14161d;
    margin-top: 20px;

    strong {
      font-family: 'FF Mark W05';
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 800;
      color: #14161d;
      letter-spacing: 0.125em;
      margin-right: 7px;
    }

    svg {
      margin-top: 2px;
    }
  }
}
