.app-ViewMini {
  .m-download {
    background: #8cac80;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    // &:before {
    //   content: '⌄';
    //   color: white;

    //   line-height: 0;
    //   height: 10px;
    //   display: block;
    //   text-align: center;
    // }
  }
  .m-download-s {
    box-shadow: 0px 0px 0px 2px #8cac80;
  }
  .m-resin {
    background: #aaacb3;
  }
  .m-resin-s {
    box-shadow: 0px 0px 0px 2px #aaacb3;
  }
  .m-steel {
    background: #898a8b;
  }
  .m-steel-s {
    box-shadow: 0px 0px 0px 2px #898a8b;
  }
  .m-brass {
    background: #e7cd48;
  }
  .m-brass-s {
    box-shadow: 0px 0px 0px 2px #e7cd48;
  }
  .m-bronze {
    background: #b17755;
  }
  .m-bronze-s {
    box-shadow: 0px 0px 0px 2px #b17755;
  }
  .m-silver {
    background: #dbdbdb;
  }
  .m-silver-s {
    box-shadow: 0px 0px 0px 2px #dbdbdb;
  }
  .m-gold {
    background: #ffcd58;
  }
  .m-gold-s {
    box-shadow: 0px 0px 0px 2px #ffcd58;
  }

  .goldButton {
    background: #fbce00;
    box-shadow: inset 0px 0px 0px 3px #fbce00;
    background-clip: padding-box;
    text-shadow: 0px 1px 0px rgba(255, 235, 144, 0.55);

    padding: 1rem 1.4rem;
    // text-shadow: 0px 1px 0.5px rgb(255 235 144 / 35%);
    color: black;
    border-radius: 3px;
    font-family: 'FF Mark W05';
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 800;
    letter-spacing: 0.125em;
    box-sizing: border-box;
    svg {
      margin-top: 1px;
      font-size: 17px;
    }
  }

  .goldButton {
    position: relative;
    overflow: hidden;
    transition: 0.3s;
    // box-shadow: inset 0px 0px 0px 3px #fbce00;

    &::before {
      content: '';
      display: block;
      position: absolute;
      background: rgba(255, 242, 188, 0.3);
      width: 60px;
      height: 100%;
      top: 0;
      filter: blur(30px);
      transform: translateX(-100px) skewX(-15deg);
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      background: rgba(255, 217, 67, 0.3);
      width: 30px;
      height: 100%;
      top: 0;
      filter: blur(5px);
      transform: translateX(-100px) skewX(-15deg);
    }

    &:hover {
      // transform: scale(1.015);
      cursor: pointer;
      background: #f5c001;
      border-color: #ffea8873;

      &::before,
      &::after {
        transform: translateX(400px) skewX(-15deg);
        transition: 1.3s;
      }
    }
  }

  .featureButton {
    border-radius: 3px;
    padding: 1rem 1.4rem;
    background-clip: padding-box;
    font-size: 18px;
    border: 2px solid #53545534;
    box-shadow: inset 0px 0px 0px 2px white;
    transition: 0.3s;

    svg {
      margin-top: 1px;
      font-size: 17px;
      color: #abaeb1;
    }

    &:hover {
      background-color: #edeff54a;
      border-color: #5354553a;

      svg {
        animation: spin 1s ease-out;
      }
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }
}
