.app-CheckoutFrame {
  .-popover {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(107, 114, 128, 0.7);
    z-index: 100000;
    user-select: none;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .-iframe {
    // min-height: 500px;
    // min-width: 480px;
    // // right: 0px;
    // // top: 70px;
    // // bottom: 0px;
    // // height: calc(100vh - 70px);
    // // position: fixed;
    // // overflow-x: hidden;
    // background: #fff;

    width: 100vw;
    height: 100vh;
    background: none;
  }
}

body.app-CheckoutFrame-active {
  overflow: hidden;
  // position: fixed;
}
