@import-normalize;

body,
html,
#root {
  height: 100%;
}

.App {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

::-moz-selection {
  color: white;
  background: rgba(84, 86, 199, 0.75);
}

::selection {
  color: white;
  background: rgba(84, 86, 199, 0.75);
}
