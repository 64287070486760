.app-Testimonials {
  // .testimonialCol blockquote:last-child {
  //   margin: 4rem 0;
  // }

  // h3 {
  //   font-family: 'FF Mark W05';
  //   text-transform: uppercase;
  //   font-size: 14px;
  //   font-weight: 800;
  //   letter-spacing: 0.125em;
  //   opacity: 0.5;
  //  }

  h2 {
    font-family: 'FF Mark W05';
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 0.5px;
  }
}
