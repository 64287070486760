.app-TermsAndConditions {
  margin-top: 80px;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;

  .wrapper {
    max-width: 1000px;
  }

  h2 {
    margin-bottom: 30px;
    // font-family: 'warnock-pro', serif;
    padding-bottom: 20px;
    border-bottom: solid 1px #f0ebeb;
    font-weight: bold;
    font-size: 24px;
  }

  h3 {
    margin-bottom: 14px;
    // font-family: 'warnock-pro', serif;
    font-weight: bold;
  }

  p {
    line-height: 24px;
    margin-bottom: 14px;
    color: #000;
    // font-family: 'museo-sans', sans-serif;

    &.tos-last-point {
      margin-bottom: 28px !important;
    }
  }
}
