.app-StoreGrid {
  background: #f3f4f6;
  background: linear-gradient(to top, #f5f5f5, #ffffff);
  overflow: hidden;
  transform: translateZ(0);

  .heading,
  .subHeading {
    text-align: center;
  }

  .resinBg {
    display: block;
    width: 100%;
    height: 250px;
    background: url('../images/resin-bg.png') no-repeat;
    background-size: contain;
    background-position: center;
  }

  .downloadBg {
    display: block;
    width: 100%;
    height: 130px;
    background: url('../images/stl-icon.png') no-repeat;
    background-size: contain;
    background-position: center;
  }

  .goldButton {
    background: #fbce00;
    box-shadow: inset 0px 0px 0px 3px #fbce00;
    background-clip: padding-box;
    text-shadow: 0px 1px 0px rgba(255, 235, 144, 0.55);

    padding: 1rem 1.4rem;
    // text-shadow: 0px 1px 0.5px rgb(255 235 144 / 35%);
    color: black;
    border-radius: 3px;
    font-family: 'FF Mark W05';
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 800;
    letter-spacing: 0.125em;
    box-sizing: border-box;
    svg {
      margin-top: 1px;
      font-size: 17px;
    }
  }

  .goldButton {
    position: relative;
    overflow: hidden;
    transition: 0.3s;
    // box-shadow: inset 0px 0px 0px 3px #fbce00;

    animation: none !important;

    * {
      animation: none !important;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      background: rgba(255, 242, 188, 0.3);
      width: 60px;
      height: 100%;
      top: 0;
      filter: blur(30px);
      transform: translateX(-100px) skewX(-15deg);
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      background: rgba(255, 217, 67, 0.3);
      width: 30px;
      height: 100%;
      top: 0;
      filter: blur(5px);
      transform: translateX(-100px) skewX(-15deg);
    }

    &:hover {
      // transform: scale(1.015);
      cursor: pointer;
      background: #f5c001;
      border-color: #ffea8873;

      &::before,
      &::after {
        transform: translateX(400px) skewX(-15deg);
        transition: 1.3s;
        animation: none;
      }
    }
  }

  .featureButton {
    border-radius: 3px;
    padding: 0.8rem 1.4rem;
    background-clip: padding-box;
    font-size: 18px;
    border: 2px solid #53545534;
    box-shadow: inset 0px 0px 0px 2px white;
    transition: 0.3s;

    svg {
      margin-top: 1px;
      font-size: 17px;
      color: #abaeb1;
    }

    &:hover {
      background-color: #edeff54a;
      border-color: #5354553a;

      svg {
        animation: spin 1s ease-out;
      }
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }

  .gridContainer {
    .collectionItem {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      align-items: center;
      text-align: center;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.015),
        0px 0px 15px rgba(0, 0, 0, 0.01), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
      background: rgba(255, 255, 255, 0.25);
      margin-bottom: 20px;

      .preview {
        background: #c5cbd524;
        padding: 30px;
        min-height: 320px;
        justify-content: center;
        align-items: center;
        display: flex;

        overflow: hidden;
        position: relative;

        .quantity {
          position: absolute;
          padding: 4px 11px;
          font-size: 19px;
          letter-spacing: -0.275px;
          background: rgba(84, 86, 199, 1);
          border-radius: 7px;
          top: 20px;
          color: white;
          right: 20px;
          font-weight: 800;
        }

        &.goldCallout {
          padding: 0;
          // margin: 0;

          // background: black;

          img {
            height: 100%;
            //   // margin: 0;
            //   // padding: 0;
            width: 100%;
            object-fit: cover;
          }
        }
      }

      .content {
        padding: 25px;
      }

      h2 {
        font-family: 'FF Mark W05';
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 800;
        line-height: 42px;
        letter-spacing: 0.075em;
      }

      p {
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.3px;

        &.description {
          opacity: 0.9;
        }

        &.price {
          font-weight: 800;
          line-height: 38px;
        }
      }

      &.recommended {
        box-shadow: 0px 0px 0px 4px rgba(84, 86, 199, 0.7),
          0px 10px 50px -20px rgba(84, 86, 199, 5);
        position: relative;
        transform: scale(1.05);

        &:after {
          content: 'Most Popular';
          background: rgba(84, 86, 199, 1);
          padding: 10px;
          position: absolute;
          top: -22px;
          color: white;
          font-size: 17px;
          border-radius: 99px;
          padding: 10px 20px;
        }

        .goldButton {
          &::before,
          &::after {
            animation: goldFlash 4.5s linear 2s infinite;
          }
        }

        @keyframes goldFlash {
          0% {
            transform: translateX(-100px) skewX(-15deg);
          }
          25% {
            transform: translateX(400px) skewX(-15deg);
          }
          100% {
            transform: translateX(400px) skewX(-15deg);
          }
        }
      }
    }
  }
}
