.app-GoldCTA {
  .goldButton {
    color: #14161d;

    strong {
      font-family: 'FF Mark W05';
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 800;
      color: #14161d;
      letter-spacing: 0.125em;
      margin-right: 7px;
    }
  }
}
