.app-Inspiration {
  overflow: hidden;

  .heading {
    text-align: center;
  }

  .goldButton {
    background: #fbce00;
    box-shadow: 0px 0px 0px 3px #fbce00;
    background-clip: padding-box;

    padding: 1rem 1.4rem;
    text-shadow: 0px 1px 0px rgba(255, 235, 144, 0.55);
    color: black;
    border-radius: 1px;
    font-family: 'FF Mark W05';
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 800;
    letter-spacing: 0.125em;

    svg {
      margin-top: 1px;
    }
  }

  .goldButton {
    position: relative;
    overflow: hidden;
    transition: 0.3s;
    // box-shadow: inset 0px 0px 0px 3px #fbce00;

    &::before {
      content: '';
      display: block;
      position: absolute;
      background: rgba(255, 242, 188, 0.3);
      width: 60px;
      height: 100%;
      top: 0;
      filter: blur(30px);
      transform: translateX(-100px) skewX(-15deg);
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      background: rgba(255, 217, 67, 0.3);
      width: 30px;
      height: 100%;
      top: 0;
      filter: blur(5px);
      transform: translateX(-100px) skewX(-15deg);
    }

    &:hover {
      // transform: scale(1.015);
      cursor: pointer;
      background: #f5c001;
      border-color: #ffea8873;

      &::before,
      &::after {
        transform: translateX(400px) skewX(-15deg);
        transition: 1.3s;
      }
    }
  }

  .featureButton {
    border-radius: 3px;
    padding: 1rem 1.4rem;
    background-clip: padding-box;
    font-size: 18px;
    border: 2px solid #53545534;
    box-shadow: inset 0px 0px 0px 2px white;
    transition: 0.3s;

    &:hover {
      background-color: #edeff54a;
      border-color: #5354553a;
      // box-shadow: inset 0px 0px 0px 2px white;
      //inset 0px 0px 0px 4px #5354550b;
    }
  }
}

.app-CharacterRender {
  img {
    transition: transform 0.3s ease-in-out;
    transform: scale(1);
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}
