.app-Hero {
  margin-top: 80px;

  h1 {
    color: white;
    text-transform: uppercase;
    font-family: 'Tungsten Bold';
    letter-spacing: 5px;
    font-size: 85px;
    font-size: 7vw;
    font-weight: 400;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.7);

    @media (min-width: 1024px) {
      font-size: 85px;
    }

    @media (max-width: 640px) {
      font-size: 52px;
      letter-spacing: 3.25px;
    }

    span {
      // color: rgb(155, 168, 233);
      color: #645be8;
      font-weight: 400;
    }
  }

  .heroBg {
    background: url('../images/hero.jpg') no-repeat center bottom;
    background-color: #0c0d11;
    background-size: cover;

    @media (min-width: 768px) {
      padding: 0;
      position: relative;
      height: calc(100vh - 80px);
    }
  }

  p {
    font-family: 'DINNextLTW04-Light';
    font-size: 32px;
    font-size: 2.85vw;
    line-height: 38px;
    letter-spacing: 0.5px;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.7);
    color: rgba(160, 169, 186, 0.91);

    @media (max-width: 640px) {
      font-size: 23px;
      line-height: 28px;
    }

    @media (min-width: 1024px) {
      font-size: 32px;
    }
  }

  .goldButton {
    background: #fbce00;
    padding: 0.65rem 1.75rem;
    color: black;
    font-weight: bold;
    border-radius: 3px;
    font-family: 'FF Mark W05';
    text-transform: uppercase;
    font-size: 17px;
    font-weight: 800;
    text-shadow: 0px 1px 0.5px rgba(255, 235, 144, 0.35);
    letter-spacing: 0.125em;

    @media screen and (max-width: 640px) {
      font-size: 14px;
      padding: 0.65rem 1rem;
    }
  }

  @media (min-width: 768px) {
    .verticalCenter {
      margin: 0;
      margin-top: -4.5vh;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }

  .goldButton {
    position: relative;
    overflow: hidden;
    transition: 0.3s;
    box-shadow: inset 0px 0px 0px 3px #fbce00;

    &::before {
      content: '';
      display: block;
      position: absolute;
      background: rgba(255, 255, 255, 0.3);
      width: 60px;
      height: 100%;
      top: 0;
      filter: blur(30px);
      transform: translateX(-100px) skewX(-15deg);
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      background: rgba(255, 255, 255, 0.1);
      width: 30px;
      height: 100%;
      top: 0;
      filter: blur(5px);
      transform: translateX(-100px) skewX(-15deg);
    }

    &:hover {
      // transform: scale(1.015);
      cursor: pointer;
      background: #f5c001;
      box-shadow: inset 0px 0px 0px 3px #ffea8873;

      &::before,
      &::after {
        transform: translateX(400px) skewX(-15deg);
        transition: 1.3s;
      }
    }
  }
}
