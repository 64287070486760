.app-SuggestForm {
  .suggestBg {
    display: block;
    width: 100%;
    height: 250px;
    background: url('../images/suggest-bg.png') no-repeat;
    background-size: contain;
    background-position: center;
  }
}
