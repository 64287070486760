.app-CollectionGrid {
  background: #f3f4f6;
  background: linear-gradient(to top, #f5f5f5, #ffffff);
  overflow: hidden;
  transform: translateZ(0);

  .heading,
  .subHeading {
    text-align: center;
  }

  .content {
    .goldButton {
      background: #fbce00;
      box-shadow: inset 0px 0px 0px 3px #fbce00;
      background-clip: padding-box;
      text-shadow: 0px 1px 0px rgba(255, 235, 144, 0.55);
      outline-color: black;
      padding: 1rem 1.4rem;
      // text-shadow: 0px 1px 0.5px rgb(255 235 144 / 35%);
      color: black;
      border-radius: 3px;
      font-family: 'FF Mark W05';
      text-transform: uppercase;
      font-size: 15px;
      font-weight: 800;
      letter-spacing: 0.125em;
      box-sizing: border-box;
      svg {
        margin-top: 1px;
        font-size: 17px;
      }
    }

    .goldButton {
      position: relative;
      overflow: hidden;
      transition: 0.3s;
      // box-shadow: inset 0px 0px 0px 3px #fbce00;

      &::before {
        content: '';
        display: block;
        position: absolute;
        background: rgba(255, 242, 188, 0.3);
        width: 60px;
        height: 100%;
        top: 0;
        filter: blur(30px);
        transform: translateX(-100px) skewX(-15deg);
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        background: rgba(255, 217, 67, 0.3);
        width: 30px;
        height: 100%;
        top: 0;
        filter: blur(5px);
        transform: translateX(-100px) skewX(-15deg);
      }

      &:hover {
        // transform: scale(1.015);
        cursor: pointer;
        background: #f5c001;
        border-color: #ffea8873;

        &::before,
        &::after {
          transform: translateX(400px) skewX(-15deg);
          transition: 1.3s;
        }
      }
    }

    .featureButton {
      border-radius: 3px;
      padding: 1rem 1.4rem;
      background-clip: padding-box;
      font-size: 18px;
      border: 2px solid #53545534;
      box-shadow: inset 0px 0px 0px 2px white;
      transition: 0.3s;
      outline-color: black;

      svg {
        margin-top: 1px;
        font-size: 17px;
        color: #abaeb1;
      }

      &:hover {
        background-color: #edeff54a;
        border-color: #5354553a;

        svg {
          animation: spin 1s ease-out;
        }
      }
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }

  .gridContainer {
    .collectionItem {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      // justify-content: center;
      align-content: center;
      align-items: center;
      text-align: left;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.015),
        0px 0px 15px rgba(0, 0, 0, 0.01), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
      background: rgba(255, 255, 255, 0.25);

      .preview {
        height: 400px;
        width: 100%;
        background: #c5cbd524;
        // padding: 30px;
        overflow: hidden;
        cursor: pointer;

        img {
          object-fit: contain;
          object-position: bottom center;
          padding: 30px;
          width: 100%;
          height: 100%;
          cursor: pointer;
          transition: 0.4s;

          &:hover {
            transform: scale(1.015);
            transform-origin: center center;
            filter: brightness(110%);
          }
        }
      }

      .content {
        padding: 28px 28px 35px 28px;
        display: flex;
        width: 100%;
        height: 300px;
        // justify-content: center;
        align-items: center;
        flex-wrap: wrap;
      }

      h2 {
        font-family: 'FF Mark W05';
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 800;
        line-height: 26px;
        letter-spacing: 0.075em;
        height: 50px;
        width: 100%;
        display: flex;
        // justify-content: center;
        // text-align: center;
        align-items: center;
      }

      p {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.3px;
        margin: 5px 0;
        // text-align: center;
        // width: 100%;
      }
    }
  }
}
