.app-Newsletter {
  background-image: url('../images/form-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center left;
  box-shadow: inset 0px 4px 3px rgba(21, 16, 41, 0.18),
    inset 0px -4px 3px rgba(21, 16, 41, 0.18);

  @media (max-width: 640px) {
    background-image: url('../images/form-bg-mob.jpg');
    background-position: center center;
  }

  h2 {
    font-family: 'FF Mark W05';
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 0.125em;
  }

  p {
    color: #c7c6ffc7;
  }

  input {
    background: #100e208a;
    background-clip: padding-box;
    border-radius: 4px;
    border: 1px solid #6053c44d;
    transition: 0.2s;

    &:hover {
      background-color: darken(#100e208a, 2.5);
    }

    &:focus {
      background-color: darken(#100e208a, 2.5);
      outline: none !important;
      border-color: #6053c44d;
      box-shadow: 0px 0px 0px 2px #6053c461;
    }

    &::placeholder {
      color: #c7c6ffc7;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: #c7c6ffc7;
    }

    &::-ms-input-placeholder {
      color: #c7c6ffc7;
    }
  }

  .subscribeButton {
    border-radius: 4px;
    background-clip: padding-box;
    border: 1px solid transparent;
    background-color: #6e5fddba;
    transition: 0.2s;

    &:hover {
      background-color: lighten(#6e5fddba, 2.5);
      box-shadow: 0px 3px 8px #0d051d1a, inset 0px 1px 2px #e3d1ff0f;
    }

    &:active,
    &:focus {
      outline: none;
      box-shadow: 0px 0px 1px 0px black, 0px 0px 0px 2px #6053c461;
    }
  }
}
