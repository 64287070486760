.app-GoldHero {
  margin-top: 80px;
  overflow: hidden;

  h1 {
    color: black;
    text-transform: uppercase;
    font-family: 'Tungsten Bold';
    letter-spacing: 5px;

    font-weight: 400;
    text-shadow: 0 2px 1px rgba(255, 224, 38, 0.4);

    font-size: 200px;
    line-height: 200px;

    // border: 5px solid #ffd261bf;
    // display: inline-block;
    // box-shadow: 0px 3px 8px #34250726;
    // padding: 25px 40px;

    // border-bottom: 5px solid #ffd261bf;

    span {
      font-weight: 400;
    }
  }

  .heroBg {
    background: url('../images/goldbg.jpg')
      no-repeat center bottom;
    background-color: #0c0d11;
    background-size: cover;
    overflow: hidden;
    box-shadow: inset 0px 0px 500px #b17300cc;

    @media (min-width: 768px) {
      padding: 0;
      position: relative;
      height: calc(100vh - 80px);
    }
  }

  p {
    font-family: 'DINNextLTW04-Light';

    line-height: 38px;
    letter-spacing: 0.5px;
    margin: 0 auto;
    font-size: 39px;
    line-height: 52px;
    color: #3c1900cf;
    text-shadow: 0 2px 1px rgba(255, 224, 38, 0.25);
    width: 730px;
  }

  // .goldButton {
  //   background: #fbce00;
  //   padding: 0.65rem 1.75rem;
  //   color: black;
  //   font-weight: bold;
  //   border-radius: 3px;
  //   font-family: 'FF Mark W05';
  //   text-transform: uppercase;
  //   font-size: 17px;
  //   font-weight: 800;
  //   text-shadow: 0px 1px 0.5px rgba(255, 235, 144, 0.35);
  //   letter-spacing: 0.125em;

  //   @media screen and (max-width: 640px) {
  //     font-size: 14px;
  //     padding: 0.65rem 1rem;
  //   }
  // }

  @media (min-width: 768px) {
    .verticalCenter {
      margin: 0;
      margin-top: -4.5vh;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }

  @media (max-width: 768px) {
    .verticalCenter {
      // zoom: 0.8;
      text-align: center;
    }

    .subHeading {
      font-size: 45px !important;
    }

    h1 {
      font-size: 115px !important;
      line-height: 100px !important;
    }

    .ribbon {
      width: 350px;
      line-height: 32px !important;
    }

    .icon {
      display: none;
    }
  }

  @media (max-width: 640px) {
    .ribbon {
      line-height: 26px !important;
      font-size: 20px !important;
      width: 75%;
    }

    .subHeading {
      padding-top: 20px;
      font-size: 37px !important;
      line-height: 30px !important;
    }

    h1 {
      font-size: 90px !important;
      line-height: 100px !important;
    }

    // .verticalCenter {
    //   // zoom: 0.5;
    // }
  }

  .icon {
    // color: #ffcd418f;
    color: #ffd256b8;
    filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.25));
    position: absolute;
    width: 100px;
    bottom: 25px;
    transform: translateX(-50%) translateY(-50%);
    left: 50%;
    transition: 0.3s;
    cursor: pointer;
    z-index: 2;

    &:hover {
      color: #ffffff;
      bottom: 20px;
    }
  }

  .subHeading {
    font-family: 'FF Mark W05';
    text-transform: uppercase;
    font-size: 74px;
    font-weight: 500;
    color: white;
    display: block;
    line-height: 80px;
    letter-spacing: 0.185em;
    margin-bottom: 10px;
    text-shadow: 0px 2px 2px rgba(60, 24, 8, 0.283);
    position: relative;
    left: 5px;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    mix-blend-mode: color-dodge;
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
  }

  .top {
    z-index: 2;
    position: relative;
    height: 100%;
  }

  .ribbon {
    display: inline-block;
    color: white;
    padding: 20px 0px 20px 0;
    font-size: 25px;
    margin: 0px 0 25px 0;
    position: relative;
    line-height: 28px;
    // filter: saturate(1.15);

    &:not(.-with-anvl-suffix) {
      .whiteLogo {
        opacity: 0;
        display: none;
        width: 20px;
      }

      .ribbonBg {
        padding-right: 20px;
      }

      .ribbonBg:after {
        right: -35px;
      }
    }

    strong {
      position: relative;
      z-index: 2;
    }
  }

  .ribbonBg {
    opacity: 0.7;
    backdrop-filter: blur(10px);
    background: #c13232;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    &:after {
      content: '';
      position: absolute;
      clip-path: polygon(
        100% 0%,
        calc(100% - 0.75rem) 50%,
        100% 100%,
        0 100%,
        0% 50%,
        0 0
      );
      width: 50px;
      height: 100%;
      background: #c13232;
      right: -35px;
      top: 0px;
    }

    &:before {
      content: '';
      position: absolute;
      clip-path: polygon(
        100% 0%,
        calc(100% - 0.75rem) 50%,
        100% 100%,
        0 100%,
        0% 50%,
        0 0
      );
      transform: rotate(-180deg);
      width: 50px;
      height: 100%;
      background: #c13232;
      left: -35px;
      top: 0px;
    }
  }
}
