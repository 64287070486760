@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import-normalize; */

@layer utilities {
  @variants responsive {
    .text-shadow {
      text-shadow: 2px 2px 4px black;
    }
    .text-shadow-md {
      text-shadow: 4px 4px 8px black;
    }
    .text-shadow-lg {
      text-shadow: 15px 15px 30px black;
    }
    .text-shadow-none {
      text-shadow: none;
    }
  }
}

@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css';

@font-face {
  font-family: 'FF Mark W05';
  src: url('./fonts/ffmark/8a533745-7bc5-4dcd-8552-d9952208de6f.eot?#iefix');
  src: url('./fonts/ffmark/8a533745-7bc5-4dcd-8552-d9952208de6f.eot?#iefix')
      format('eot'),
    url('./fonts/ffmark/ffbb4591-1a9c-443b-9b6e-6ceb6a3ca76b.woff2')
      format('woff2'),
    url('./fonts/ffmark/e44c8656-a389-4ecb-838c-3c135565d6b3.woff')
      format('woff'),
    url('./fonts/ffmark/fcdcba61-8d4e-41db-9c06-24a5238587f1.ttf')
      format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'FF Mark W05';
  src: url('./fonts/ffmark/54af9b67-908f-49ac-8aa0-d3959c0e28dc.eot?#iefix');
  src: url('./fonts/ffmark/54af9b67-908f-49ac-8aa0-d3959c0e28dc.eot?#iefix')
      format('eot'),
    url('./fonts/ffmark/b821b539-3f6c-4ee1-8d8d-c331fb7aedce.woff2')
      format('woff2'),
    url('./fonts/ffmark/7368a75b-895f-4b33-ac15-1364f4ff3f9f.woff')
      format('woff'),
    url('./fonts/ffmark/9cd874e4-d629-4f8a-8760-b877ec0e5d9e.ttf')
      format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'FF Mark W05';
  src: url('./fonts/ffmark/0f774eeb-e868-4bd6-9055-79542afd5208.eot?#iefix');
  src: url('./fonts/ffmark/0f774eeb-e868-4bd6-9055-79542afd5208.eot?#iefix')
      format('eot'),
    url('./fonts/ffmark/cdb13a7a-2f13-4f7c-b7a3-01b4ccef574d.woff2')
      format('woff2'),
    url('./fonts/ffmark/40a8594b-08b2-4a38-97d9-958c95360b20.woff')
      format('woff'),
    url('./fonts/ffmark/bcc8880e-b967-43ce-a210-d1404cbdc736.ttf')
      format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'FF Mark W05';
  src: url('./fonts/ffmark/653875a7-77e2-4995-97f2-4c9de734eb69.eot?#iefix');
  src: url('./fonts/ffmark/653875a7-77e2-4995-97f2-4c9de734eb69.eot?#iefix')
      format('eot'),
    url('./fonts/ffmark/445f40df-cbad-41e8-92eb-b4438eb872fc.woff2')
      format('woff2'),
    url('./fonts/ffmark/ed8af8bb-2ddb-4128-a83b-837173705425.woff')
      format('woff'),
    url('./fonts/ffmark/7401384a-83d5-4f49-a886-089029ce641c.ttf')
      format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'FF Mark W05';
  src: url('./fonts/ffmark/5cd9741a-b33e-4cd7-a197-e850a6e920b2.eot?#iefix');
  src: url('./fonts/ffmark/5cd9741a-b33e-4cd7-a197-e850a6e920b2.eot?#iefix')
      format('eot'),
    url('./fonts/ffmark/601fdeac-544b-4132-8e0d-f24e0a72e489.woff2')
      format('woff2'),
    url('./fonts/ffmark/680a604b-6cec-4a82-8d1c-3a77fb66cee5.woff')
      format('woff'),
    url('./fonts/ffmark/ef6af46e-3064-450c-9902-48bb726bd026.ttf')
      format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'FF Mark W05';
  src: url('./fonts/ffmark/96eee7b7-99e1-4bc6-9099-86d14aa4b25a.eot?#iefix');
  src: url('./fonts/ffmark/96eee7b7-99e1-4bc6-9099-86d14aa4b25a.eot?#iefix')
      format('eot'),
    url('./fonts/ffmark/ec5c34fc-3ad0-4147-9b77-e978a00b7653.woff2')
      format('woff2'),
    url('./fonts/ffmark/e2a9f569-6858-48b4-b5c0-30c014123cd1.woff')
      format('woff'),
    url('./fonts/ffmark/fb00b17a-2053-49b7-9d53-68cf5c842ba1.ttf')
      format('truetype');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'FF Mark W05';
  src: url('./fonts/ffmark/69d25c38-dbab-4326-9230-923d3c18889b.eot?#iefix');
  src: url('./fonts/ffmark/69d25c38-dbab-4326-9230-923d3c18889b.eot?#iefix')
      format('eot'),
    url('./fonts/ffmark/7bc581c3-bb28-4f5d-a9c5-3018fcfbfbd9.woff2')
      format('woff2'),
    url('./fonts/ffmark/4e811424-cd97-4afb-bf5a-965c3b39905d.woff')
      format('woff'),
    url('./fonts/ffmark/124573c7-0c5e-4c89-8a3a-4ee9aee5d3a9.ttf')
      format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'FF Mark W05';
  src: url('./fonts/ffmark/b3dc84d3-a366-4d54-85cd-a4a909be2322.eot?#iefix');
  src: url('./fonts/ffmark/b3dc84d3-a366-4d54-85cd-a4a909be2322.eot?#iefix')
      format('eot'),
    url('./fonts/ffmark/e5071d6e-c3d6-4c88-8042-a4c33b65387f.woff2')
      format('woff2'),
    url('./fonts/ffmark/53426c00-fe27-497e-bafe-d62c9c2f02b5.woff')
      format('woff'),
    url('./fonts/ffmark/1d0866b5-0d7c-4fde-988c-c45899a3503f.ttf')
      format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'FF Mark W05';
  src: url('./fonts/ffmark/b9275bbe-5494-4561-8869-49b8b4213b0e.eot?#iefix');
  src: url('./fonts/ffmark/b9275bbe-5494-4561-8869-49b8b4213b0e.eot?#iefix')
      format('eot'),
    url('./fonts/ffmark/e6f5bc67-2b84-4b0a-b32a-0ec6c2b8634e.woff2')
      format('woff2'),
    url('./fonts/ffmark/b428d7d4-bc34-4bdf-a27b-13bf549f613c.woff')
      format('woff'),
    url('./fonts/ffmark/2864b8c0-7389-464a-845c-23d708d5665c.ttf')
      format('truetype');
  font-weight: 950;
  font-style: normal;
}

@font-face {
  font-family: 'Tungsten Bold';
  src: url('./fonts/tungsten/561f38b1f4570de0fb8a39d691ab058c.eot');
  src: url('./fonts/tungsten/561f38b1f4570de0fb8a39d691ab058c.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/tungsten/561f38b1f4570de0fb8a39d691ab058c.woff2')
      format('woff2'),
    url('./fonts/tungsten/561f38b1f4570de0fb8a39d691ab058c.woff') format('woff'),
    url('./fonts/tungsten/561f38b1f4570de0fb8a39d691ab058c.ttf')
      format('truetype');
}

@font-face {
  font-family: 'DINNextW01-Bold';
  src: url('./fonts/dinnext/78c77b16334d415ac17d5a812345a0a1.eot');
  src: url('./fonts/dinnext/78c77b16334d415ac17d5a812345a0a1.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/dinnext/78c77b16334d415ac17d5a812345a0a1.woff2')
      format('woff2'),
    url('./fonts/dinnext/78c77b16334d415ac17d5a812345a0a1.woff') format('woff'),
    url('./fonts/dinnext/78c77b16334d415ac17d5a812345a0a1.ttf')
      format('truetype');
}

@font-face {
  font-family: 'DINNextLTW04-Medium';
  src: url('./fonts/dinnext/0201813274259c3d4f78760d19270bab.eot');
  src: url('./fonts/dinnext/0201813274259c3d4f78760d19270bab.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/dinnext/0201813274259c3d4f78760d19270bab.woff2')
      format('woff2'),
    url('./fonts/dinnext/0201813274259c3d4f78760d19270bab.woff') format('woff'),
    url('./fonts/dinnext/0201813274259c3d4f78760d19270bab.ttf')
      format('truetype');
}

@font-face {
  font-family: 'DINNextLTW04-Light';
  src: url('./fonts/dinnext/3403275a8aeb6fc07122a8dd7f10e0ec.eot');
  src: url('./fonts/dinnext/3403275a8aeb6fc07122a8dd7f10e0ec.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/dinnext/3403275a8aeb6fc07122a8dd7f10e0ec.woff2')
      format('woff2'),
    url('./fonts/dinnext/3403275a8aeb6fc07122a8dd7f10e0ec.woff') format('woff'),
    url('./fonts/dinnext/3403275a8aeb6fc07122a8dd7f10e0ec.ttf')
      format('truetype');
}

body {
  /* font-family: 'DINNextLTW04-Light'; */
  letter-spacing: 0.3px;
}

.headingFont {
  font-family: 'FF Mark W05';
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.5px;
}

.goldButtonApp {
  background: #fbce00;
  text-shadow: 0px 1px 0.5px rgba(255, 235, 144, 0.35);
  color: black;
  font-weight: bold;
  border-radius: 3px;
  font-family: 'FF Mark W05';
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 800;
  text-shadow: 0px 1px 0px rgba(255, 235, 144, 0.55);
  letter-spacing: 0.125em;
}

.goldButtonApp {
  position: relative;
  overflow: hidden;
  transition: 0.3s;
  display: inline-block;
  box-shadow: inset 0px 0px 0px 3px #fbce00;

  &::before {
    content: '';
    display: block;
    position: absolute;
    background: rgba(255, 242, 188, 0.3);
    width: 60px;
    height: 100%;
    top: 0;
    filter: blur(30px);
    transform: translateX(-100px) skewX(-15deg);
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    background: rgba(255, 217, 67, 0.3);
    width: 30px;
    height: 100%;
    top: 0;
    filter: blur(5px);
    transform: translateX(-100px) skewX(-15deg);
  }

  &:hover {
    // transform: scale(1.015);
    cursor: pointer;
    background: #f5c001;
    box-shadow: inset 0px 0px 0px 3px #ffea8873;

    &::before,
    &::after {
      transform: translateX(400px) skewX(-15deg);
      transition: 1.3s;
    }
  }
}
