.app-Heading {
  .heading {
    font-family: 'FF Mark W05';
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 0.5px;
    margin: 10px auto;
    text-align: left;

    strong {
      font-weight: 800;
      color: rgba(99, 102, 241, 1);
    }

    @media (min-width: 768px) {
      text-align: center;
      margin: 20px auto;
    }

    @media (min-width: 1024px) {
      letter-spacing: 0.5px;
    }

    @media (min-width: 1280px) {
    }
  }

  .subHeading {
    font-size: 19px;
    text-transform: none;
    color: rgba(99, 102, 241, 1);
    text-align: left;
    font-weight: 800;
    line-height: 28px;

    @media (min-width: 768px) {
      text-align: center;
      font-size: 26px;
      line-height: 37px;
    }

    @media (min-width: 1024px) {
      font-size: 33px;
    }
  }

  .ribbon {
    background: #1471af;
    display: inline-block;
    position: relative;
    padding: 0.75rem 0.25rem;
    background-clip: padding-box;
    color: white;

    &::before {
      content: '';
      position: absolute;
      clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
      width: 50px;
      height: 100%;
      background: #1471af;
      left: -45px;
      top: 0px;
    }

    &::after {
      content: '';
      position: absolute;
      clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
      width: 50px;
      height: 100%;
      background: #1471af;
      right: -45px;
      top: 0px;
    }

    @media (max-width: 640px) {
      background: none;
      color: rgba(107, 114, 128, 1);
      font-size: 22px;
      padding: 0 30px;
      line-height: 28px;

      &::after,
      &::before {
        transform: rotate(180deg);
      }
    }
  }
}

.heading {
  font-family: 'FF Mark W05';
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.5px;
  margin: 10px auto;

  strong {
    font-weight: 800;
    color: rgba(99, 102, 241, 1);
  }
}
