.app-Blueprint {
  background: url('../images/features-tech-section-bg.png') repeat;
  position: relative;
  overflow: hidden;

  .gradient {
    width: 100%;
    height: 100%;
    background-image: radial-gradient(
      circle at top left,
      rgba(16, 136, 246, 0.175),
      transparent
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .container {
    z-index: 2;
    position: relative;
  }

  .goldButton {
    background: #fbce00;
    text-shadow: 0px 1px 0px rgba(255, 235, 144, 0.55);
    box-shadow: 0px 0px 0px 3px #fbce00;
    background-clip: padding-box;
    line-height: 22px;

    padding: 1rem 1.4rem;
    // text-shadow: 0px 1px 0.5px rgb(255 235 144 / 35%);
    color: black;
    border-radius: 1px;
    font-family: 'FF Mark W05';
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 800;
    letter-spacing: 0.125em;
    svg {
      margin-top: 1px;
    }
  }

  .goldButton {
    position: relative;
    overflow: hidden;
    transition: 0.3s;
    // box-shadow: inset 0px 0px 0px 3px #fbce00;

    &::before {
      content: '';
      display: block;
      position: absolute;
      background: rgba(255, 242, 188, 0.3);
      width: 60px;
      height: 100%;
      top: 0;
      filter: blur(30px);
      transform: translateX(-100px) skewX(-15deg);
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      background: rgba(255, 217, 67, 0.3);
      width: 30px;
      height: 100%;
      top: 0;
      filter: blur(5px);
      transform: translateX(-100px) skewX(-15deg);
    }

    &:hover {
      // transform: scale(1.015);
      cursor: pointer;
      background: #f5c001;
      border-color: #ffea8873;

      &::before,
      &::after {
        transform: translateX(400px) skewX(-15deg);
        transition: 1.3s;
      }
    }
  }

  .featureButton {
    border-radius: 3px;
    padding: 1rem 1.4rem;
    background-clip: padding-box;
    font-size: 18px;
    border: 2px solid #53545534;
    box-shadow: inset 0px 0px 0px 2px white;
    transition: 0.3s;

    &:hover {
      background-color: #edeff54a;
      border-color: #5354553a;
      // box-shadow: inset 0px 0px 0px 2px white;
      //inset 0px 0px 0px 4px #5354550b;
    }
  }

  .subHeading {
    font-size: 28px;
    color: #cbd8ff;
  }

  .heading {
    color: white;
    font-family: 'FF Mark W05';
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 0.5px;
    margin-bottom: 30px;
  }

  .paragraph {
    margin-top: 20px;
    color: #cbd8ff;
    font-size: 20px;
    line-height: 32px;
  }

  .steps {
    background: #0005106e;
    padding: 10px 15px;
    backdrop-filter: blur(1px);
    justify-content: space-around;
    border-radius: 20px 0 20px 0;
    width: 100%;

    i {
      color: #d8e2ff7a;
    }

    span {
      color: white;
      text-transform: uppercase;
    }
  }

  @media (min-width: 768px) {
    .blockLeft {
      width: 55%;
    }

    .blockRight {
      width: 45%;
    }

    // .steps {
    //   width: 95%;
    // }
  }
}
