.app-GoldFeatures {
  scroll-padding-top: 100px;

  .anvil {
    height: 64px;
    width: 112px;
    background: url('../images/anvil-icon.png');
    background-size: cover;
    margin: 0;
    // margin-left: -10px;
    margin: 0 auto 30px;
    display: block;

    @media (min-width: 768px) {
      margin: 0 auto 35px;
    }
  }

  .heading,
  .subHeading {
    text-align: center;
  }

  .anchor {
    width: 100%;
    height: 80px;
    position: absolute;
    top: 0;
    margin-top: -80px;
  }

  .app-GoldFeatureItem {
    .heading {
      margin: unset;
      margin-top: 20px;
    }

    .icon {
      svg {
        // width: 100%;
        // height: 100%;
        font-size: 38px;
      }
    }
  }
}
