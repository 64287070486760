.app-UserBasket-panel {
  background: #555;

  .-basket-item {
    img {
      height: 120px;
      width: auto;
    }
  }

  .-basket-quote {
    display: flex;
    flex-flow: column nowrap;
    padding: 20px;

    > *:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .-basket-coupon {
    padding: 20px;

    input {
      background-color: #555;
    }
  }
}
