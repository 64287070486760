.app-GoldPricing {
  .groupShot {
    background: url('../images/group2.jpg') no-repeat;
    background-size: cover;
    background-position: center 450px;
    padding-bottom: 400px;

    @media (max-width: 640px) {
      background-size: contain;
      background-position: center bottom;
      padding-bottom: 150px;
    }
  }

  .goldButton strong {
    //   background: #fbce00;
    //   padding: 0.6rem 1.4rem;
    //   text-shadow: 0px 1px 0.5px rgba(255, 235, 144, 0.35);
    //   color: black;
    // font-weight: bold;
    //   border-radius: 3px;
    font-family: 'FF Mark W05';
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 800;
    color: white;
    //   text-shadow: 0px 1px 0px rgba(255, 235, 144, 0.55);
    letter-spacing: 0.125em;
    margin-right: 7px;
  }

  .glow {
    // box-shadow: 0px 0px 250px #ffa708b5;
    // filter: drop-shadow(0px 10px 200px #ffa7087a);
    filter: drop-shadow(0px 10px 200px #c1bdff61);
  }
}
