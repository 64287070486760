.my-masonry-grid {
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > a {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
  border-radius: 12px;
  height: 200px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.7);

  @media (max-width: 1024px) {
    height: 150px;
  }

  @media (max-width: 768px) {
    height: 100px;
  }
}

// .blur-lg:hover {
//   filter: blur(5px);
//   transition: 0.75 filter linear;
// }
