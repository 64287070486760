.app-ThreeSteps {
  .stepBox {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02), 0px 0px 15px rgba(0, 0, 0, 0.025),
      inset 0 0 0 1px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    text-align: center;
    padding: 0;
    background: rgba(255, 255, 255, 0.4);

    .stepHeader {
      position: relative;
      height: 140px;

      .stepTitle {
        position: absolute;
        top: 0;
        left: 0;
        line-height: 130px;
        width: 100%;
        color: white;
        text-align: center;
        font-family: 'FF Mark W05';
        text-transform: uppercase;
        font-size: 30px;
        font-weight: 800;
        letter-spacing: 0.125em;
      }

      img {
        height: 140px;
        width: 100%;
        object-fit: cover;
      }
    }

    p {
      line-height: 28px;
      font-family: 'DINNextLTW04-Light';
      font-size: 18px;
      letter-spacing: 0.5px;
    }

    .stepNumber {
      display: block;
      width: 100px;
      height: 99px;
      margin: -50px auto -5px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      text-align: center;
      line-height: 95px;
      font-size: 25px;
      color: #ffffff;
      font-weight: bold;
      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
      text-indent: 1px;
      z-index: 5;
      position: relative;
      font-family: 'FF Mark W05';
    }

    &#step-1 {
      .stepNumber {
        background-image: url('../images/hex-1.png');
      }

      .stepButton {
        background-color: #1471af;

        &:hover {
          background-color: darken(#1471af, 4);
        }

        &:active,
        &:focus {
          box-shadow: 0 0 0 2px white, 0 0 0 4px #1471af;
        }
      }
    }

    &#step-2 {
      .stepNumber {
        background-image: url('../images/hex-2.png');
      }

      .stepButton {
        background-color: #298c13;

        &:hover {
          background-color: darken(#298c13, 4);
        }

        &:active,
        &:focus {
          box-shadow: 0 0 0 2px white, 0 0 0 4px #298c13;
        }
      }
    }

    &#step-3 {
      .stepNumber {
        background-image: url('../images/hex-3.png');
      }

      .stepButton {
        background-color: #b5241f;

        &:hover {
          background-color: darken(#b5241f, 4);
        }

        &:active,
        &:focus {
          box-shadow: 0 0 0 2px white, 0 0 0 4px #b5241f;
        }
      }
    }
  }

  .stepButton {
    padding: 0.65rem 1.75rem;
    font-weight: bold;
    border-radius: 3px;
    font-weight: 800;
    letter-spacing: 0.125em;
    font-family: 'DINNextLTW04-Light';
    font-size: 19px;
    letter-spacing: 0.75px;
    // transition: background-color 0.2s;
  }
}
