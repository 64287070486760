@keyframes animProps {
  0% {
    transform: scale(1);
    opacity: 0.9;
  }
  25% {
    opacity: 0.75;
  }
  50% {
    transform: scale(1.035);
    opacity: 1;
  }
  75% {
    opacity: 0.75;
  }
  to {
    transform: scale(1);
    opacity: 0.9;
  }
}

.app-Features {
  overflow: hidden;

  .featureBlock {
    video {
      transform: scale(1.1);
      margin-top: 20px;

      @media (max-width: 1024px) {
        margin-left: 15px;
      }
    }

    .featureImage {
      width: 100%;
      background: url('../images/home-hand.png') no-repeat;
      height: 690px;
      background-position: bottom right;
      background-size: contain;
      position: absolute;
      top: -240px;
      right: -15px;
    }

    .animProps {
      background: url('../images/anim-props1.png') no-repeat;
      height: 690px;
      background-size: contain;
      background-position: bottom right;
      position: absolute;
      width: 105%;
      top: -320px;
      right: -28px;
      animation: animProps 5.75s ease-in-out infinite;
      opacity: 0.7;
    }
    .animProps2 {
      background: url('../images/anim-props2.png') no-repeat;
      height: 620px;
      background-size: contain;
      background-position: bottom right;
      position: absolute;
      width: 105%;
      top: -330px;
      right: -30px;
      animation: animProps 5.75s ease-in-out infinite;
      animation-delay: 2s;
      opacity: 0.9;
    }
  }

  .featureHeading {
    font-family: 'FF Mark W05';
    text-transform: uppercase;
    // font-size: 43px;
    font-weight: 800;
    // line-height: 54px;
    letter-spacing: 0.125em;
  }

  .featureContent p {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.3px;
  }

  .featureContent ul li {
    font-family: 'DINNextLTW04-Medium';
    color: black;
    // line-height: 38px;
    font-size: 18px;
    margin-bottom: 15px;

    .bg-yellow-300 {
      background-color: #d2d4dc;
    }
  }

  .featureButton {
    padding: 0.65rem 1.75rem;
    font-weight: bold;
    border-radius: 3px;
    font-weight: 800;
    letter-spacing: 0.125em;
    font-family: 'DINNextLTW04-Light';
    font-size: 19px;
    letter-spacing: 0.75px;
    // transition: background-color 0.2s;
  }
}

.placeholder {
  // background: #f7f7f9;
  margin-right: 30px;
  height: 520px;

  em {
    line-height: 520px;
    opacity: 0.5;
  }
}

@media (max-width: 1200px) {
  .animProps,
  .animProps2 {
    display: none;
  }

  .app-Features .featureBlock .featureImage {
    height: 720px;
    top: -270px;
    right: -30px;
  }
}

@media (max-width: 1200px) {
  .app-Features .featureBlock .featureImage {
    height: 45vh;
    right: 0;
  }

  .featureBlock {
    video {
      transform: scale(1);
      margin-top: 20px;
      pointer-events: none;
    }
  }
}
