.app-NewsletterSlim {
  background: url('../images/form-bg-legacy.png') repeat;

  h2 {
    // font-family: 'FF Mark W05';
    // text-transform: uppercase;
    font-weight: 800;
    // letter-spacing: 0.125em;
    color: white;
    margin-right: 35px;
    line-height: 4rem;

    @media (max-width: 768px) {
      font-size: 22px;
      line-height: 34px;
    }

    @media (min-width: 1024px) and (max-width: 1280px) {
      font-size: 2.2vw;
    }
  }

  .input {
    background: rgba(52, 52, 52, 0.325);
    border: 1px solid rgba(255, 255, 255, 0.085);
    box-shadow: inset 1.5px 1.5px 2px rgba(0, 0, 0, 0.4);
    color: #ffffff;
    // margin-right: 20px;

    transition: 0.3s;
    min-width: 0;

    &[type='name'] {
      flex-shrink: 3;
    }
    &[type='email'] {
      flex-shrink: 1;
    }

    &:hover {
      border-color: rgba(255, 255, 255, 0.25);
    }

    &:focus {
      outline: none;
      border-color: rgba(255, 255, 255, 0.45);
      // box-shadow: 0px 0px 0px 2px #6053c461;
    }

    &::placeholder {
      color: #ededf2c7;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: #c7c6ffc7;
    }

    &::-ms-input-placeholder {
      color: #c7c6ffc7;
    }
  }

  .subscribeButton {
    border-radius: 4px;
    background-clip: padding-box;
    border: 1px solid transparent;
    background: #c13232b3;
    transition: 0.2s;

    &:hover {
      background-color: lighten(#d32424b3, 5);
    }

    &:active,
    &:focus {
      outline: none;
    }
  }
}
