.app-ShowReel {
  // background: #f3f4f6;
  // background: linear-gradient(to bottom, #ecedee, #ffffff);
  // box-shadow: inset 0px 2px 2px #e4e7eb;
  overflow: hidden;

  .goldButton {
    background: #fbce00;
    text-shadow: 0px 1px 0px rgba(255, 235, 144, 0.55);
    box-shadow: 0px 0px 0px 3px #fbce00;
    background-clip: padding-box;

    padding: 1rem 1.4rem;
    // text-shadow: 0px 1px 0.5px rgb(255 235 144 / 35%);
    color: black;
    border-radius: 1px;
    font-family: 'FF Mark W05';
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 800;
    letter-spacing: 0.125em;
    svg {
      margin-top: 1px;
    }
  }

  .goldButton {
    position: relative;
    overflow: hidden;
    transition: 0.3s;
    // box-shadow: inset 0px 0px 0px 3px #fbce00;

    &::before {
      content: '';
      display: block;
      position: absolute;
      background: rgba(255, 242, 188, 0.3);
      width: 60px;
      height: 100%;
      top: 0;
      filter: blur(30px);
      transform: translateX(-100px) skewX(-15deg);
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      background: rgba(255, 217, 67, 0.3);
      width: 30px;
      height: 100%;
      top: 0;
      filter: blur(5px);
      transform: translateX(-100px) skewX(-15deg);
    }

    &:hover {
      // transform: scale(1.015);
      cursor: pointer;
      background: #f5c001;
      border-color: #ffea8873;

      &::before,
      &::after {
        transform: translateX(400px) skewX(-15deg);
        transition: 1.3s;
      }
    }
  }

  .featureButton {
    border-radius: 3px;
    padding: 1rem 1.4rem;
    background-clip: padding-box;
    font-size: 18px;
    border: 2px solid #53545534;
    box-shadow: inset 0px 0px 0px 2px white;
    transition: 0.3s;

    &:hover {
      background-color: #edeff54a;
      border-color: #5354553a;
      // box-shadow: inset 0px 0px 0px 2px white;
      //inset 0px 0px 0px 4px #5354550b;
    }
  }

  .imageMarq {
    width: 150%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 70px auto 50px auto;
    transform: scale(1.1);

    @media (max-width: 768px) {
      width: 100%;
      display: grid;
      grid-template-columns: auto auto auto auto;
      transform: none;
      margin: 50px auto 30px auto;
    }

    @media (max-width: 640px) {
      transform: scale(1.1);
    }

    .features-item-container {
      display: inline-block;
      background: rgba(255, 255, 255, 0.2);
      border: 2px solid rgba(37, 42, 55, 0.1);
      border-radius: 15px;
      box-shadow: 0 1px 3px rgba(20, 23, 31, 0.05);
      margin: 0 35px;
      padding: 25px;
      cursor: default;
      vertical-align: middle;
      transform: scale(1.1);
      animation: bounce 7s ease-in-out infinite;

      img {
        min-height: 100px;
        min-width: 100px;
      }

      @media (max-width: 768px) {
        margin: 10px;
        padding: 8px !important;
        transform: none;

        img {
          max-height: 100px;
          margin: 0 auto;
        }
      }

      &:nth-child(2n) {
        animation-delay: 1s;
      }

      &.big {
        transform: scale(1.3);
        padding: 35px 25px;
      }

      &.med {
        transform: scale(1.2);
      }
    }
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  to {
    transform: translateY(0);
  }
}
