.app-DealItem {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  > * {
    flex: 1;
    max-width: 950px;

    zoom: 1.1;
  }

  &:not(:last-child) > * {
    margin-bottom: 0px;
  }

  strong,
  b {
    font-family: 'DINNextLTW04-Medium';
  }

  @media (max-width: 1024px) {
    .deal {
      flex-flow: column;

      > * {
        margin: 5px 0;
      }

      .arrow {
        display: none;
      }

      p {
        overflow: visible;
        text-overflow: '';
        white-space: normal;
        text-align: center;
      }
    }
  }
}

.app-Deals {
  .dealContainer {
    background-image: url('../images/groupshot.jpg');
    background-size: cover;
  }
}
