.app-CollectionShareModal {
  z-index: 15;
  .backdrop-blur-2xl {
    backdrop-filter: blur(5px);
  }

  .socialIcons a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    svg {
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        transform: scale(1.015);
        filter: brightness(110%);
      }
    }
  }
}
