.app-SubpageHero {
  margin-top: 80px;

  h1 {
    color: white;
    text-transform: uppercase;
    font-family: 'Tungsten Bold';
    letter-spacing: 5px;
    font-size: 64px;
    font-weight: 400;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.7);
    line-height: 56px;

    // @media (min-width: 1024px) {
    //   font-size: 85px;
    // }

    @media (max-width: 640px) {
      font-size: 50px;
      letter-spacing: 3.15px;
    }

    span {
      // color: rgb(155, 168, 233);
      color: #645be8;
      font-weight: 400;
    }
  }

  .heroBg {
    background: no-repeat center bottom;
    background-color: #0c0d11;
    background-size: cover;
    overflow: hidden;
    position: relative;
    padding: 0;
    background-image: url('../images/collection-banner.jpg');

    &.collectionHero {
      background-image: url('../images/collection-banner.jpg');
    }
    &.dealsHero {
      background-image: url('../images/deals-banner.jpg');
    }
    &.contactHero {
      background-image: url('../images/contact-banner.jpg');
    }
    &.partnersHero {
      background-image: url('../images/partners-banner.jpg');
    }
    &.ambassadorHero {
      background-image: url('../images/ambassador-banner.jpg');
    }
    &.adventHero {
      background-image: url('../images/advent-banner.jpg');
    }
    &.pressHero {
      background-image: url('../images/press-banner.jpg');
    }
    &.storeHero {
      background-image: url('../images/store-banner.jpg');
    }
    &.charactersHero {
      background-image: url('../images/characters-banner.jpg');
      background-position: center;
    }
    &.suggestHero {
      background-image: url('../images/suggest-banner.jpg');
      background-position: bottom;
    }

    @media (min-width: 768px) {
      height: 400px;
    }

    // .overlay {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   content: '';
    //   background: #0c0d1182;
    //   // background-image: url('./images/hero-blank.jpg');
    //   // opacity: 0.7;
    //   backdrop-filter: blur(12px);
    // }
  }

  .tagline {
    font-family: 'DINNextLTW04-Light';
    font-size: 22px;
    line-height: 38px;
    letter-spacing: 0.5px;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.7);
    color: rgba(236, 237, 247, 0.78);

    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  .goldButton {
    background: #fbce00;
    padding: 0.65rem 1.75rem;
    color: black;
    font-weight: bold;
    border-radius: 3px;
    font-family: 'FF Mark W05';
    text-transform: uppercase;
    font-size: 17px;
    font-weight: 800;
    text-shadow: 0px 1px 0.5px rgba(255, 235, 144, 0.35);
    letter-spacing: 0.125em;

    @media screen and (max-width: 640px) {
      font-size: 14px;
      padding: 0.65rem 1rem;
    }
  }

  @media (min-width: 768px) {
    .verticalCenter {
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      z-index: 1;
    }
  }

  .hand {
    height: 100%;
    background: url('../images/hand.png');
    background-size: contain;
    background-position: right bottom;
    position: absolute;
    width: 850px;
    right: 0;
    margin-right: 0;
    bottom: 0;
    z-index: 2;
    background-repeat: no-repeat;
  }

  .ribbon {
    display: inline-block;
    color: white;
    padding: 20px 0px 20px 25px;
    font-size: 25px;
    margin: 10px 0 25px 0;
    position: relative;
    line-height: 28px;

    &:not(.-with-anvl-suffix) {
      .whiteLogo {
        opacity: 0;
        display: none;
        width: 20px;
      }

      .ribbonBg {
        padding-right: 20px;
      }

      .ribbonBg:after {
        right: -35px;
      }
    }

    strong {
      position: relative;
      z-index: 2;
    }

    .whiteLogo {
      background: url('../images/logo-white.png') no-repeat;
      display: inline-block;
      text-indent: -999em;
      width: 75px;
      background-size: contain;
      position: relative;
      top: 5px;
    }

    @media (max-width: 768px) {
      padding: 13px 0px 13px 17px;
      font-size: 21px;

      .ribbonBg:after {
        right: -30px;
      }
    }
  }

  .ribbonBg {
    opacity: 0.7;
    backdrop-filter: blur(10px);
    background: #c13232;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    &:after {
      content: '';
      position: absolute;
      clip-path: polygon(
        100% 0%,
        calc(100% - 0.75rem) 50%,
        100% 100%,
        0 100%,
        0% 50%,
        0 0
      );
      width: 50px;
      height: 100%;
      background: #c13232;
      right: -35px;
      top: 0px;
    }
  }

  .screenshot {
    background: white;
    position: relative;
    z-index: 1;
    width: 200px;
    height: 300px;
  }

  &.miniBanner .heroBg {
    height: 12.5rem;
  }
}
