.app-ContactExtras {
  .linkContainer {
    a {
      width: 100%;
      display: inline-block;

      span {
        transition: 0.3s;
        margin-top: 1px;
      }
    }

    a:hover span {
      margin-left: 3px;
    }
  }
}
