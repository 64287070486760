.app-MissionStatement {
  .tryLink {
    border-bottom: 1px solid rgba(255, 255, 255, 0.625);
    display: inline-block;
    padding-bottom: 7px;
    width: auto;

    span {
      font-size: 13px;
      margin: 2px 0 0 5px;
      transition: 0.3s;
    }

    &:hover {
      span {
        margin-left: 10px;
      }
    }
  }
}
